<template>
  <div style="width: 100%">
    <div class="school" v-if="$store.state.pc">
      <div class="head">
        <h1 :style="this.$route.path == '/nhwx' ? 'display: none;' : ''">
          {{ themeOther.name }}
        </h1>

        <div style="display: none">{{ themeOther }}</div>
        <div class="block" v-if="bannerList.length >= 2">
          <el-carousel height="300px">
            <el-carousel-item v-for="(item, index) in bannerList" :key="index">
              <img :src="item.url" alt="" />
            </el-carousel-item>
          </el-carousel>
        </div>

        <div class="banner" v-else>
          <img :src="bannerList[0].url" alt="" />
        </div>
      </div>
      <div class="content">
        <p><span>项目列表</span></p>
        <div class="content_text">
          <div class="left">
            <div class="content_title" v-for="(item, index) in themeData" :key="index" :class="[
      currentIndex == index
        ? 'content_title_current'
        : 'content_title',
    ]" @click="tabTitle(index)">
              {{ item.name }}
            </div>
          </div>
          <div class="right"
            v-if="themeData[currentIndex] && themeData[currentIndex].children && themeData[currentIndex].children.length > 0">
            <div class="ducation" v-for="(item, index) in themeData[currentIndex].children" :key="index">
              <div class="education_left">
                <div class="child1">
                  <div class="child2">{{ index + 1 }}</div>
                  <div class="child3"></div>
                </div>
              </div>
              <div class="education_center">
                <p>{{ item.name }}</p>
                <p>{{ item.explain }}</p>
              </div>
              <div class="education_right" @click="gocontent(item)">
                查看详情
              </div>
            </div>

            <div class="pages">
              <el-pagination background layout="prev, pager, next" :page-size="4"
                :total="themeData[currentIndex].children.length" style="margin-left: 250px; margin-top: 15px">
              </el-pagination>
            </div>
          </div>
        </div>
        <basecopyRight></basecopyRight>
      </div>
    </div>

    <div class="school" v-else>
      <div class="head">
        <h1 :style="this.$route.path == '/nhwx' ? 'display:none' : ''">
          {{ themeOther.name }}
        </h1>
        <div class="block" v-if="bannerList.length >= 2">
          <el-carousel height="200px">
            <el-carousel-item v-for="(item, index) in bannerList" :key="index">
              <img :src="item.url" alt="" />
            </el-carousel-item>
          </el-carousel>
        </div>
        <img :src="bannerList[0].url" alt="" width="100%" height="100%" v-else />
      </div>
      <div class="content">
        <div class="content_text">
          <van-collapse v-model="activeName" accordion class="dym_collapse">
            <van-collapse-item class="collapse_item" :title="item.name" :name="index + 1"
              v-for="(item, index) in themeData" :key="index">
              <div class="conteny_data" v-for="(item1, index1) in item.children" :key="index1">
                <p>{{ item1.name }}</p>
                <p>{{ item1.explain }}</p>
                <div class="look" @click="gocontent(item1)">查看详情</div>
              </div>
            </van-collapse-item>
          </van-collapse>
        </div>
      </div>
    </div>
    <!-- <div class="foor"><basecopyRight></basecopyRight></div> -->
  </div>
</template>

<script>
import { zhuanlan_index, banner_list, index_list } from "../../api/api";
import basecopyRight from "../../components/Public/BasecopyRight/basecopyRight.vue";
export default {
  components: { basecopyRight },
  data() {
    return {
      themeOther: JSON.parse(localStorage.getItem("themeOther")),
      themeData: JSON.parse(localStorage.getItem("themeData")),
      activeName: "",
      bannerList: [{ url: "" }],
      total: 8,
      currentIndex: 0,
      // 项目列表数据
    };
  },
  created() {
    // this.color();
    this.getbanner();
    console.log(2222);
    // 主题色
    // this.color();
    // this.$router.push({
    //     path: `/${localStorage.getItem("urlL")}/one?project_id=51&name=年人智能运用技术`,

    //   });
    console.log(this.$route.fullPath == "/nhwx");
  },
  mounted() {
  },
  methods: {
    // color() {

    //   if (localStorage.getItem('themeOther') && localStorage.getItem('themeOther').color) {

    //     document
    //       .getElementsByTagName("body")[0]
    //       .style.setProperty("--test", localStorage.getItem('themeOther').color);
    //   }

    // },

    getData() {
      // 接口
      // xxx().then((res) => {
      //   xxx(res.data.projectList[0].id).then((res1) => {});
      // });
    },
    gocontent(value) {
      this.$store.commit("domTitle", value.name);
      this.$router.push({
        path: `/${localStorage.getItem("urlL")}/one`,
        query: { project_id: value.id, name: value.name },
      });
      this.$store.state.name = value.name;
    },
    tabTitle(index) {
      this.currentIndex = index;
      // 调接口
    },
    // 获取banner
    getbanner() {
      banner_list({ zl_url: localStorage.getItem("urlL") }).then((res) => {
        this.bannerList = res.data.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../style/default/default.scss";

.pc {
  .school {
    position: relative;
    left: 50%;
    transform: translateX(-50%);

    .head {
      position: relative;

      h1 {
        margin: 0;
        position: absolute;
        z-index: 88;
      }

      .block {
        .el-carousel__indicators {
          width: 100%;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }

      .banner {
        img {
          width: 100%;
          height: 100%;
        }

        width: 100%;
        height: 300px;
      }

      color: white;
      width: 100%;
      height: 300px;
      background-size: 100%;
      position: relative;

      h1 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%);
      }
    }

    .content {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 1400px;
      min-height: 480px;

      p {
        span {
          padding-left: 15px;
          border-left: 3px solid $brown;
        }
      }

      .content_text {
        width: 1400px;
        min-width: 1400px;
        display: flex;
        justify-content: space-between;

        .left {
          min-height: 480px;
          width: 302px;
          max-width: 302px;

          .content_title {
            &:hover {
              cursor: pointer;
            }

            width: 100%;
            height: 60px;
            color: #999999;
            border-radius: 5px;

            background: white;
            line-height: 60px;
            text-align: center;
            margin-bottom: 10px;
            font-weight: bold;
          }

          .content_title_current {
            width: 100%;
            height: 60px;
            margin-bottom: 10px;
            color: white;
            border-radius: 5px;
            background: $brown;
            line-height: 60px;
            text-align: center;
            font-weight: bold;
          }
        }

        .right {
          min-height: 480px;
          background: white;
          width: 1084px;
          max-width: 1084px;
          box-sizing: border-box;
          padding: 10px 20px;

          .pages {
            ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
              background-color: $brown;
            }
          }

          .ducation {
            width: 100%;
            max-height: 100px;
            max-width: 1084px;
            height: 100px;
            border-bottom: 1px solid #cccccc;
            display: flex;
            justify-content: space-between;

            .education_left {
              padding-top: 15px;
              width: 5%;
              height: 100px;
              text-align: center;
              font-size: 14px;
              color: white;
              background-position: center;
              position: relative;

              .child1 {
                position: absolute;
                color: white;
                width: 30px;
                height: 30px;
                position: relative;

                .child2 {
                  line-height: 20px;
                  position: absolute;
                  left: 50%;
                  width: 20px;
                  height: 20px;
                  top: 50%;
                  z-index: 1;
                  transform: translate(-50%);
                  background: $brown;
                }

                .child3 {
                  color: white;
                  box-sizing: border-box;
                  position: absolute;
                  z-index: 12;
                  width: 20px;
                  height: 20px;
                  left: 60%;
                  top: 60%;
                  transform: translate(-50%);
                  border: 2px solid $brown;
                }
              }
            }

            .education_center {
              width: 72%;
              height: 100%;
              line-height: 30px;
              padding-top: 20px;

              p {
                padding: 0;
                margin: 0;
                padding-bottom: 5px;

                &:nth-child(2) {
                  color: #999999;
                  font-size: 14px;
                }
              }
            }

            .education_right {
              width: 20%;
              color: $brown;
              line-height: 100px;
              text-align: center;

              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}

.mob {
  width: 100%;

  .school {
    width: 100%;
    position: relative;

    .head {
      height: 18.75rem;
      width: 100%;
      position: relative;

      .block {
        img {
          height: 100%;
          width: 100%;

          /deep/.el-carousel {
            height: 18.75rem;

            .el-carousel__container {
              height: 18.75rem;

              .el-carousel__item {
                height: 18.75rem;
              }

              .el-carousel__arrow {
                height: 18.75rem;
              }
            }
          }
        }
      }

      h1 {
        margin: 0;
        color: white;
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: 88;
        font-size: 2.25rem;
        transform: translate(-50%, -50%);
      }
    }

    .content {
      margin-top: 20px;
      width: 100%;
      position: relative;

      /deep/.van-collapse-item__content {
        background: #f5f5f5;
      }

      .content_text {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 90%;

        .dym_collapse {
          /deep/ .van-collapse-item__title--expanded {
            background: $brown;
            border-radius: 5px;
          }

          /deep/ .collapse_item {
            .van-cell__title {
              height: 5rem;
              font-size: 1.875rem;
              line-height: 5rem;

              .van-icon {
                line-height: 5rem !important;
              }

              .van-cell__right-icon,
              .van-icon {
                line-height: 5rem;
                margin-top: 20px;
              }
            }
          }

          margin-bottom: 20px;
          height: 6.25rem;

          /deep/ .collapse_item {
            margin-top: 20px;
          }

          /deep/ .van-collapse-item__content {
            background-color: #f1f1f1;

            /deep/ .van-collapse-item {
              margin-bottom: 20px;
            }

            .van-collapse-item__title {
              height: 100px;
            }

            .conteny_data {
              min-height: 12.5rem;
              position: relative;
              padding: 20px 15px;
              border: 1px solid $brown;
              border-radius: 5px;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              margin-bottom: 10px;
              background: white;

              p {
                &:nth-child(1) {
                  color: #333333;
                  font-size: 2rem;
                  margin-top: 0;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }

                &:nth-child(2) {
                  color: #999999;
                  font-size: 1.6rem;
                }
              }

              .look {
                width: 50%;
                position: absolute;
                left: 50%;
                height: 4rem;
                text-align: center;
                line-height: 4rem;
                transform: translateX(-50%);
                background: #f2eee6;
                color: $brown;
                font-size: 1.625rem;
              }
            }
          }
        }
      }
    }

    .foor {
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
}
</style>
